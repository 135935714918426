@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a:hover {
  color: blueviolet;
}

@layer base {
  a {
    @apply cursor-pointer;
  }

}

.bg-img::before {
  content: '';
  width: 30%;
  height: 40%;
  position: absolute;
  bottom: 0px;
  right: -2px;
  background-image: radial-gradient(circle at 1px 1px, rgb(136, 41, 232) 1.5px, transparent 0);
  background-size: 25px 25px;
 
}

.bg-img::after {
  content: '';
  width: 30%;
  height: 40%;
  position: absolute;
  top: 0px;
  left: 0;
  background-image: radial-gradient(circle at 1px 1px, rgb(136, 41, 232) 1.5px, transparent 0);
  background-size: 25px 25px;
}



.wave::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: -10%;
  width: 100%;
  height: 40%;
  background:
    radial-gradient(farthest-side at 50% 125%, #0000 47%, rgb(218, 201, 234) 55% 45%, transparent 44%),
    radial-gradient(farthest-side at 50% -15%, #0000 47%, rgb(218, 201, 234) 55% 45%, transparent 44%);

  background-size: 200px 40px;
  background-position: 100px 20px, 0px 20px;
  transform: rotate(20deg);
  
}
.baner-img{
  width: 80%;
  height: 90%;
  position: absolute;
  background-color: rgb(237, 225, 250);
  opacity: 0.9;
  top: 10%;
  right: 10%;
  border-radius: 15px;
}

.baner-img::after{
  content: '';
  position: absolute;
  bottom: 0%;
  right: 20%;
  width: 100%;
  height: 80%;
  background:
    radial-gradient(farthest-side at 50% 125%, #0000 47%, rgb(218, 201, 234) 55% 45%, transparent 44%),
    radial-gradient(farthest-side at 50% -15%, #0000 47%, rgb(218, 201, 234) 55% 45%, transparent 44%);
  background-size: 200px 40px;
  background-position: 100px 20px, 0px 20px;
  transform: rotate(35deg);
  
}

.btn{
  @apply  bg-violet-500
  text-white rounded-full px-8 py-2  border-2 border-violet-400 hover:bg-violet-700 shadow-2xl shadow-violet-200;
}
.btn-outline{
  @apply border-2 rounded-full border-violet-400 px-8 py-2  hover:bg-violet-200 hover:border-violet-100 shadow-2xl shadow-violet-200 ;
}
.offer::before{
  content: url(./../public/img/offer.png);
  position: absolute;
  top: -15px;
  left: -12px;
  transform: rotate(-45deg);
}
