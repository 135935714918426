.bg-slid::after {
    content: '';
    width: 30%;
    height: 35%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: radial-gradient(circle at 1px 1px, rgb(136, 41, 232) 1px, transparent 0);
    background-size: 10px 10px;
  }
  .bg-slid::before {
    content: '';
    width: 30%;
    height: 35%;
    position: absolute;
    bottom: 0px;
    right:-02px;
    background-image: radial-gradient(circle at 1px 1px, rgb(136, 41, 232) 1px, transparent 0);
    background-size: 10px 10px;

  }
  